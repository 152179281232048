<template>
  <v-app id="inspire">
    <Vmenu />
    <v-card>
      <v-card-title>
        {{ menu }}
        <div class="text-center">
          <v-btn class="mx-1" fab dark small color="primary">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn class="mx-1" fab dark small color="success" @click="renewdata">
            <v-icon dark>
              mdi-autorenew
            </v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details 
          @input="getdata"
        ></v-text-field>
      </v-card-title>
      <v-simple-table class="elevation-1">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                No
              </th>
              <th class="text-left">
                Nama
              </th>
              <th class="text-left">
                Jenis Kelamin
              </th>
              <th class="text-left">
                Agama
              </th>
              <th class="text-left">
                Pendidikan
              </th>
              <th class="text-left">
                Aksi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in datatabel"
              :key="item.no"
            >
              <td>{{ item.no }}</td>
              <td>{{ item.nama }}</td>
              <td>{{ item.jeniskalmin }}</td>
              <td>{{ item.agama }}</td>
              <td>{{ item.pendidikan }}</td> 
              <td>
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </td> 
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-container class="grey lighten-5">
      <v-row no-gutters>
        <v-col 
          cols="12" 
        >
          <div class="text-center"> 
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="purple"
                @click="minusdata"
              >
                <v-icon dark>
                  mdi-arrow-left-bold
                </v-icon>
              </v-btn>

              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="purple"
                @click="nextdata"
              >
                <v-icon dark>
                  mdi-arrow-right-bold
                </v-icon>
              </v-btn>
          </div>  
        </v-col>
      </v-row>
    </v-container> 
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      search: "",
      page:1,
      pageCount:8,
      datatabel: [],
      menu: "test server",      
      loading: true,  
    };
  },
  watch: { 
    
  },
  computed: {
    // selectedContent() {       
    //   return this.datatabel;
    // },
  },
  methods: {
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        search: this.search,
        page: this.page,
        itemsPerPage:10
      };  
      // console.log(datakirim)
      await axios
        .post("https://saebo.technology/apiotp/api/penduduk", datakirim)
        .then(response => {
          if (response.data.status === 1) {
            this.datatabel = response.data.data; 
            this.pageCount = response.data.lengthpage 
          }  
        })
        .catch(errros => {
          console.log("error");
        });
      
      this.loading = false;  
    },
    editItem(item) {
      console.log(item);
    },
    deleteItem(item) {
      console.log(item);
    },  
    minusdata(){ 
      this.page = this.page - 1;
      if(this.page <= 1){
        this.page = 1;
      }
      this.getdata()
    },
    nextdata(){
      this.page = this.page + 1;
      if(this.page >= this.pageCount){
        this.page = this.pageCount;
      }
      this.getdata();
    }, 
    selectedContent() {       
      return this.datatabel;
    },
    renewdata() {
      this.page = 1;
      this.getdata();
    }
  },
  created() { 
    this.getdata(); 
  },
  async mounted() {  
    // await this.getdata(); 
  },
};
</script>
