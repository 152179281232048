<template>
  <div class="app">
    <v-app-bar fixed dark color="light-blue">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ appname }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="child in item.items" :key="child.title">
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-footer app dark color="light-blue" height="35" inset>
      <v-row justify="center" no-gutters>
        <v-col class="text-center white--text" cols="12">
          {{ new Date().getFullYear() }} — <strong>{{ appname }}</strong>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<style scoped>
.app {
  padding-bottom: 50px;
}
</style>

<script>
import Constant from "@/components/Constant.vue";

export default {
  name: "Vmenu",
  data: () => ({
    drawer: null,
    appname: Constant.APP_NAME,
    items: [
      {
        action: "mdi-ticket",
        items: [{ title: "List Item" }],
        title: "Attractions"
      },
      {
        action: "mdi-silverware-fork-knife",
        active: true,
        items: [
          { title: "Breakfast & brunch" },
          { title: "New American" },
          { title: "Sushi" }
        ],
        title: "Dining"
      },
      {
        action: "mdi-school",
        items: [{ title: "List Item" }],
        title: "Education"
      },
      {
        action: "mdi-run",
        items: [{ title: "List Item" }],
        title: "Family"
      },
      {
        action: "mdi-bottle-tonic-plus",
        items: [{ title: "List Item" }],
        title: "Health"
      },
      {
        action: "mdi-content-cut",
        items: [{ title: "List Item" }],
        title: "Office"
      },
      {
        action: "mdi-tag",
        items: [{ title: "List Item" }],
        title: "Promotions"
      }
    ]
  }),
  created() {
    if (Constant.USER_APP == "" && Constant.PASS_APP == "") {
      this.$router.push({ name: "Home" });
    }
  },
  mounted() {}
};
</script>