<template>
  <v-app id="inspire">
    <Vmenu />
    <v-card>
      <v-card-title>
        {{ menu }}
        <div class="text-center">
          <v-btn class="mx-1" fab dark small color="primary">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn class="mx-1" fab dark small color="success">
            <v-icon dark>
              mdi-autorenew
            </v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="datatabel" :search="search">
        <template v-slot:item.aksi="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template></v-data-table
      >
    </v-card>
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      search: "",
      headers: [
        {
          text: "Kode",
          align: "start",
          sortable: true,
          value: "kode"
        },
        { text: "Nama", value: "nama" },
        { text: "No Rekening", value: "norek" },
        { text: "Pemilik Rekening", value: "pemilik" },
        { text: "No Akun", value: "pos" },
        { text: "Aksi", value: "aksi" }
      ],
      datatabel: []
    };
  },
  methods: {
    getdata() { 
      const datakirim = {
        user: Constant.USER_APP,
        pass: Constant.PASS_APP,
        menu: this.menu
      };

      axios
        .post(Constant.API_ADDR + "bank/daftarbank", datakirim)
        .then(response => {
          if (response.data.status === 1) {
            this.datatabel = response.data.data;
          } else {
            Constant.USER_APP = "";
            Constant.PASS_APP = "";
          }
        })
        .catch(errros => {
          console.log("error");
          Constant.USER_APP = "";
          Constant.PASS_APP = "";
        });
    },
    editItem(item) {
      console.log(item);
    },
    deleteItem(item) {
      console.log(item);
    }
  },
  created() {
    this.getdata();
  },
  mounted() {}
};
</script>
