<script>
const API_ADDR = "http://192.168.3.109/api_cashless/";
const USER_APP = "";
const PASS_APP = "";
const MENU_APP = "Daftar Bank";
const IDH = 0;
const IDD = 0;
const JNS = "TAMBAH";
const FRM = "DAFTAR";

export default {
  API_ADDR: API_ADDR,
  IDH: IDH,
  IDD: IDD,
  JNS: JNS,
  FRM: FRM,
  APP_NAME: "BUKU KAS",
  USER_APP: USER_APP,
  PASS_APP: PASS_APP,
  MENU_APP: MENU_APP,
};
</script>
