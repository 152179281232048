<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="nohp"
                    label="No Hp"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    :maxlength="maxnohp"
                    autocomplete="off"
                    :rules="[rules.required]"
                    v-on:keyup="valhp()"
                  ></v-text-field>

                  <v-text-field
                    v-model="pin"
                    id="password"
                    label="PIN"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    :maxlength="maxpin"
                    :rules="[rules.required, rules.minpin]"
                    hint="At least 6 characters"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login">Login</v-btn>
              </v-card-actions>
              <v-card-text class="red--text">{{ psn }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import Constant from "@/components/Constant.vue";
import axios from 'axios';

export default {
  name: "Login",
  props: {
    source: String
  },
  data() {
    return {
      nohp: "083195491064",
      pin: "696969",
      maxnohp: 15,
      maxpin: 6,
      rules: {
        required: value => !!value || "Required. Number Only",
        minpin: v => v.length <= 6 || "Min 6 characters"
      },
      psn: ""
    };
  },
  methods: {
    valhp() {
      // var validasiAngka = /^[0-9]+$/;
      // if (!this.nohp.match(validasiAngka)) {
      //   this.nohp = this.nohp.replace(/[^0-9]/g, "");
      // }
      this.nohp = this.nohp.replace(/[^0-9]/g, "");
    },
    async login() {
      const datakirim = {
        nohp: this.nohp,
        pin: this.pin
      };

      await axios
        .post(Constant.API_ADDR + "login/loginpin", datakirim)
        .then(response => {
          if (response.data.status === 1) {
            // alert(response.data.message);
            // this.psn = response.data.message;
            // this.dialog = false; 
            Constant.USER_APP = this.nohp;
            Constant.PASS_APP = this.pin;
            this.$router.push({ name: "Daftar" });
          } else {
            // alert(response.data.message);
            this.psn = response.data.message;
            Constant.USER_APP = "";
            Constant.PASS_APP = "";
          }
        })
        .catch(errros => {
          console.log("error");
          Constant.USER_APP = "";
          Constant.PASS_APP = "";
        });
    }
  },
  created() {},
  async mounted() {
    // await this.login();
  }
};
</script>
