import Vue from "vue"; 
import VueRouter from "vue-router";
import Login from "../components/Login.vue";
import Content from "../components/layout/Content.vue";
import Bank from "../components/bank/Daftarbank.vue";
// import Penduduk from "../components/bank/Daftarpenduduk.vue";
import Daftar from "../components/bank/Daftar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Daftar,
  },
  {
    path: "/dashboard",
    name: "Content",
    component: Content,
  },
  {
    path: "/bank",
    name: "Bank",
    component: Bank,
  },
  // {
  //   path: "/penduduk",
  //   name: "Penduduk",
  //   component: Penduduk,
  // },
  {
    path: "/daftar",
    name: "Daftar",
    component: Daftar,
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
